
import '../../global.css'

import * as React from 'react'
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import BasePage from '../../components/basePage'
import { Colors, spacingUnit } from '../../constants'
import { FlexContainer, FlexColumn, VerticalCenter} from '../../components/flex'


const ImageContainer = styled.div`
    height: 400px;
    width: 117%;
    margin-left: -7vw;
`;

const Image = styled(GatsbyImage)`
    ${({ rightBorder }) => rightBorder && 'border-right: solid black 5px'};
    ${({ bottomBorder }) => bottomBorder && 'border-bottom: solid black 5px'};  

    flex: 1;
`;

const Content = styled.div`
    color: white;
    margin-top: ${spacingUnit * 6}px;

    h3 {
        color: ${Colors.altText};
    }
`;

const Style = styled.span`
    flex: 1;
    color: ${Colors.altText};

    @media screen and (min-width: 451px) {
        text-align: end;
    }
`;

export default function Template({
    data,
}) {
    const { markdownRemark } = data;
    const { frontmatter: {
        images,
        name,
        date,
        style
    }, html } = markdownRemark;

    return (
        <BasePage customHeader={100} pageTitle={name}>
            <Helmet>
                <meta property="og:image" content={images[0].childImageSharp.gatsbyImageData.images.fallback.src} />
            </Helmet>

            <FlexColumn fullWidth>
                <ImageContainer>            
                    <FlexContainer fullHeight>
                        <Image alt="" image={getImage(images[0])} rightBorder={images.length > 1} />
                        
                        {images.length > 1 && <FlexColumn fullHeight flex={1}>
                            <Image alt="" image={getImage(images[1])} bottomBorder={images.length > 2} />
                            {images.length > 2 && <Image alt="" image={getImage(images[2])}  />}
                        </FlexColumn>}
                    </FlexContainer>
                </ImageContainer>

                <VerticalCenter marginTop={4}>
                    <h1>{name}</h1>
                    <Style>{style}</Style>
                </VerticalCenter>

                <i>{date}</i>

                <Content dangerouslySetInnerHTML={{ __html: html }} />
            </FlexColumn>
        </BasePage>
    )
};

export const pageQuery = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                name
                style
                images {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;